<template>
  <div class="face-page" :style="{ '--ScreenWidth': ScreenWidth }">
    <div class="face-page-content">
      <p class="tip">请保持人像在取景框内</p>
      <div class="face-capture" id="face-capture">
        <video
          id="video"
          v-show="showContainer"
          playsinline
          webkit-playsinline
        ></video>
        <canvas id="refCanvas" class="canvas" v-show="showContainer" />
        <div v-if="!showContainer" class="img-face">
          <img class="imgurl" :src="imgUrl" />
        </div>
      </div>
      <p class="contentp">
        {{ showTips }}
      </p>
      <p class="eyecontentp" v-if="step == 2 && scanTip == '未检测到眼睛'">
        请摘掉眼镜或者靠近屏幕
      </p>
      <p v-if="unauth" class="unauth" v-html="unauthTips"></p>
    </div>
  </div>
</template>
<script>
import { faceCompare } from "@/api/apply";
import { mapGetters } from "vuex";
import { uploadFile } from "@/api/upload";
import { personApplyAuthUrl } from "@/api/authorization";
const ScreenWidth = 700;
const SuccessTimes = 5;

export default {
  data() {
    return {
      screenSize: {
        width: window.screen.width,
        height: window.screen.height
      },
      prePlatOrderNo: "",
      URL: null,
      streamIns: null, // 视频流
      showContainer: true, // 显示
      tracker: null,
      tipFlag: false, // 提示用户已经检测到
      flag: false, // 判断是否已经拍照
      context: null, // canvas上下文
      checks: [], // 检查数量
      removePhotoID: null, // 停止转换图片
      scanTip: "未检测到人脸", // 提示文字
      imgUrl: "",
      unauth: false,
      unauthTips: "",
      canvas: null,
      trackertask: null,
      vwidth: ScreenWidth,
      vheight: ScreenWidth,
      ScreenWidth,
      userInfo: {},
      orderData: {},
      types: [
        {
          type: "face",
          name: "人脸"
        },
        {
          type: "mouth",
          name: "嘴巴"
        },
        {
          type: "eye",
          name: "眼睛"
        }
      ],
      step: 0,
      errortimes: 0
    };
  },
  computed: {
    showTips() {
      let data = {
        未检测到人脸: "请将脸部置于提示框中",
        "人脸检测中...": "请将脸部置于提示框中",
        未检测到眼睛: "请眨眼",
        "眼睛检测中...": "请眨眼",
        未检测到嘴巴: "请张嘴",
        "嘴巴检测中...": "请张嘴"
      };
      return data[this.scanTip] || this.scanTip;
    }
  },
  created() {
    window.getAuthUrl = this.getAuthUrl;
  },
  mounted() {
    this.init();
    const scale = this.screenSize.width / 375;
    this.width = ScreenWidth * scale;
    this.height = ScreenWidth * scale;
    this.width = ScreenWidth * scale;
    this.height = ScreenWidth * scale;
    this.playVideo();
  },
  watch: {
    step(newVal) {
      if (newVal > 0 && newVal < 3) {
        this.checks = [];
        this.trackertask.stop();
        this.trackertask.removeAllListeners();
        setTimeout(() => {
          this.initTracker();
        }, 1000);
      }
      if (newVal == 3) {
        this.tackPhoto();
        document.getElementById("video").pause();
      }
    },
    checks: {
      handler(newVal) {
        if (this.step < 3) {
          this.scanTip = `${this.types[this.step].name}检测中...`;
          if (newVal.length > SuccessTimes) {
            this.step++;
          }
        }
      },
      deep: true
    }
  },
  methods: {
    ...mapGetters(["getUserOrderInfo"]),
    init() {
      const data = this.getUserOrderInfo();
      this.prePlatOrderNo = data?.prePlatOrderNo || "";
    },
    playVideo() {
      this.getUserMedia(
        {
          //摄像头拍摄的区域
          video: {
            width: 500,
            height: 500,
            facingMode: "user"
          } /* 前置优先 */
        },
        this.success,
        this.error
      );
    },
    success(stream) {
      this.streamIns = stream;
      const video = document.getElementById("video");
      // webkit内核浏览器
      this.URL = window.URL || window.webkitURL;
      if ("srcObject" in video) {
        video.srcObject = stream;
      } else {
        video.src = this.URL.createObjectURL(stream);
      }
      // 苹果手机的系统弹框会阻止js的线程的继续执行 手动0.1秒之后自动执行代码
      setTimeout(() => {
        video.play();
        this.initTracker(); // 人脸捕捉
      }, 100);
    },
    error(e) {
      console.log("e :>> ", e);
      this.scanTip = "访问用户媒体失败";
      const error = String(e).toLocaleLowerCase();
      if (error.includes("permission denied")) {
        this.unauth = true;
        this.unauthTips =
          '重新拉取摄像头(仅微信中)： "我"->"设置"->"通用"->"存储空间"->"缓存"->只勾选<span>"网页浏览插件"</span>并清理 -> "重新进入该页面"';
      }
    },
    // 访问用户媒体设备
    getUserMedia(constrains, success, error) {
      /*eslint-disable*/
      if (navigator.mediaDevices?.getUserMedia) {
        //最新标准API
        navigator.mediaDevices
          .getUserMedia(constrains)
          .then(success)
          .catch(error);
      } else if (navigator.webkitGetUserMedia) {
        //webkit内核浏览器
        navigator.webkitGetUserMedia(constrains).then(success).catch(error);
      } else if (navigator.mozGetUserMedia) {
        //Firefox浏览器
        navagator.mozGetUserMedia(constrains).then(success).catch(error);
      } else if (navigator.getUserMedia) {
        //旧版API
        navigator.getUserMedia(constrains).then(success).catch(error);
      } else {
        this.scanTip = "你的浏览器不支持访问用户媒体设备";
      }
      /*eslint-disable*/
    },
    initTracker() {
      this.flag = false;
      this.context = document.getElementById("refCanvas").getContext("2d"); // 画布
      this.canvas = document.getElementById("refCanvas");
      this.context.canvas.width = ScreenWidth;
      this.context.canvas.height = ScreenWidth;
      this.tracker = new window.tracking.ObjectTracker(
        this.types[this.step].type
      );
      // this.tracker = new window.tracking.ObjectTracker(
      //   this.types[this.step].type
      // );
      this.tracker.setInitialScale(4);
      this.tracker.setStepSize(2); // 设置步长
      this.tracker.setEdgesDensity(0.1);
      try {
        this.trackertask = window.tracking.track("#video", this.tracker); // 开始追踪
      } catch (e) {
        this.scanTip = "访问用户媒体失败，请重试";
      }
      //开始捕捉方法 一直不停的检测人脸直到检测到人脸
      this.tracker.on("track", (e) => {
        //画布描绘之前清空画布
        // this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
        // console.log('e.data :>> ', e.data);
        if (e.data.length === 0) {
          if (this.errortimes > SuccessTimes / 2) {
            this.scanTip = `未检测到${this.types[this.step].name}`;
          }
          this.errortimes++;
        } else {
          this.errortimes = 0;
          // e.data.forEach((rect) => {
          //   //设置canvas 方框的颜色大小
          //   this.context.strokeStyle = "#42e365";
          //   this.context.lineWidth = 2;
          //   this.context.strokeRect(rect.x, rect.y, rect.width, rect.height);
          // });
          this.checks.push(e.data[0]);
        }
      });
    },
    //6.拍照 绘制照片，计算照片大小，腾讯人脸比对接口对照片大小和格式有要求。
    tackPhoto() {
      // 在画布上面绘制拍到的照片
      this.context.drawImage(
        document.getElementById("video"),
        0,
        0,
        this.vwidth,
        this.vwidth
      );
      // 保存为base64格式
      this.imgUrl = this.saveAsPNG(document.getElementById("refCanvas"));
      /** 拿到base64格式图片之后就可以在this.compare方法中去调用后端接口比较了，也可以调用getBlobBydataURI方法转化成文件再去比较
       * 我们项目里有一个设置个人头像的地方，先保存一下用户的图片，然后去拿这个图片的地址和当前拍照图片给后端接口去比较。
       * */
      this.compare(this.imgUrl);
      //判断图片大小
      // this.imgSize();
      this.close();
    },
    imgSize() {
      if (this.imgUrl) {
        // 获取base64图片byte大小
        const equalIndex = this.imgUrl.indexOf("="); // 获取=号下标
        let size;
        if (equalIndex > 0) {
          const str = this.imgUrl.substring(0, equalIndex); // 去除=号
          const strLength = str.length;
          const fileLength = strLength - (strLength / 8) * 2; // 真实的图片byte大小
          size = Math.floor(fileLength / 1024); // 向下取整
          console.log("size", size + "KB");
        } else {
          const strLength = this.imgUrl.length;
          const fileLength = strLength - (strLength / 8) * 2;
          size = Math.floor(fileLength / 1024); // 向下取整
          console.log("size", size + "KB");
        }

        if (size > 1024) {
          // 图片超过1M 按比例压缩
          this.imgUrl = document
            .getElementById("refCanvas")
            .toDataURL("image/png", 1024 / size);
        }
      }
    },
    getBlobBydataURI(dataURI, type) {
      var binary = window.atob(dataURI.split(",")[1]);
      var array = [];
      for (var i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
      }
      return new Blob([new Uint8Array(array)], {
        type: type
      });
    },
    async compare(url) {
      try {
        let blob = this.getBlobBydataURI(url, "image/jpeg");
        const file = new File(
          [blob],
          "file_" + Date.parse(new Date()) + ".jpg",
          { lastModified: Date.now() }
        );
        const { ossAccessUrl } = await uploadFile(file);
        const { data } = await faceCompare({
          prePlatOrderNo: this.prePlatOrderNo,
          url: ossAccessUrl
        });
        if (data.isMeFlag) {
          // 成功
          console.log("success");
          // this.$router.replace("/home");
          // this.$router.replace("/credit-extension");
          this.getAuthUrl();
        } else {
          // 失败
          console.log("fail");
          this.$router.replace(`/identity-portrait`);
        }
      } catch (error) {
        this.scanTip = error;
        setTimeout(() => {
          this.$router.replace(`/identity-portrait`);
        }, 1500);
      }
    },
    // 获取云签url
    async getAuthUrl() {
      try {
        if (!this.prePlatOrderNo) throw "订单编号错误";
        this.$toast.loading({
          message: "加载授权书...",
          forbidClick: true,
          duration: 0
        });
        const res = await personApplyAuthUrl(this.prePlatOrderNo);
        const urlReg =
          /^(((ht|f)tps?):\/\/)?([^!@#$%^&*?.\s-]([^!@#$%^&*?.\s]{0,63}[^!@#$%^&*?.\s])?\.)+[a-z]{2,6}\/?/;
        if (res.data && urlReg.test(res.data))
          window.location.href = res.data || "";
        else throw "授权书地址错误";
        // this.openAuth();
      } catch (error) {
        this.$errMsg(error);
      }
    },
    saveAsPNG(c) {
      return c.toDataURL("image/png", 0.4);
    },
    close() {
      this.flag = false;
      this.tipFlag = false;
      this.showContainer = false;
      this.context = null;
      //this.scanTip = '人脸识别结束';
      clearTimeout(this.removePhotoID);
      if (this.streamIns) {
        this.streamIns.enabled = false;
        this.streamIns.getTracks()[0].stop();
        this.streamIns.getVideoTracks()[0].stop();
      }
      this.streamIns = null;
      this.trackertask.stop();
      this.tracker = null;
    }
  }
};
</script>
<style lang="scss" scoped>
.face-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  &-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .face-capture {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    margin: 60px 30px;
    width: calc(var(--ScreenWidth) * 1px);
    height: calc(var(--ScreenWidth) * 1px);
    .canvas {
      position: absolute;
      width: 100%;
      right: 100%;
      height: 100%;
      top: 0;
      left: 0;
      bottom: 0;
    }
    video {
      transform: rotateY(180deg);
      height: 100%;
    }
  }
  .contentp {
    font-size: 32px;
    font-weight: 500;
    color: #333333;
  }
}

.eyecontentp {
  position: fixed;
  bottom: 8vh;
  left: 0;
  right: 0;
  text-align: center;
}
.unauth {
  margin-top: 12px;
  font-size: 24px;
  font-weight: 500;
  color: #666;
  padding: 0 32px;
  :deep(span) {
    color: #ee0a24;
  }
}

.tip {
  top: 96px;
  z-index: 5;
  font-size: 36px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  line-height: 50px;
}

.face-capture video,
.face-capture canvas {
  object-fit: cover;
  z-index: 2;
  width: 100%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-radius: 50%;
}

.img-face {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.img-face .imgurl {
  width: calc(var(--ScreenWidth) * 1px);
  height: calc(var(--ScreenWidth) * 1px);
  border-radius: 50%;
}
</style>
